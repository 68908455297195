import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import FaqAccordion from "../../../components/faq/faq-accordion";
import Button from "../../../components/custom-widgets/button";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import List from "../../../components/custom-widgets/list";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";
import LoanOfficerSearch from "../../../components/loan-officers/loan-officer-search";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

import CustomLargeCards from "../../../components/card/custom-large-cards";
import NewUIWafdBankBenefits from "../../../components/wafd-bank-benefits/new-ui-wafd-bank-benefits";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import HelpSection from "../../../components/home-loans/help-section";
import ServiceStatesLinks from "../../../components/service-states/service-states-links";
import PreApprovalWorkflow from "../../../components/home-loans/pre-approval-workflow";

const LotLoans = () => {
  const title = "Land Loan Calculator and Financing",
    description =
      "Discover the best bank for land loan financing to help build your dream home. Check out WaFd Bank's land loan calculator and current rates today.";
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(relativePath: { eq: "cards/thumbnail-buying-land-build-house-071023.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(
        relativePath: { eq: "cards/articles-cards/thumbnail-custom-construction-loan-1-05152023-730.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(
        relativePath: { eq: "cards/articles-cards/thumbnail-construction-to-permanent-loan-010824.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/thumbnail-custom-construction-video-120523.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const heroChevronData = {
    id: "land-loans-hero",
    ...getHeroImgVariables(pageData),
    altText: "Father hugging his kids in an open lot.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Put Your Dream Home Right Where You Want It"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-1",
            text: "See Today's Rates",
            url: "/personal-banking/home-loans/mortgage-rates",
            class: "btn-white",
            containerClass: "mb-3"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Lot Loans"
    }
  ];

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/land-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-land-loans-12-22-2022.jpg"
      }
    ]
  };

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "House project in progress."
  };

  const serviceStateLinksData = {
    class: "text-center",
    hasTexasLink: true,
    stateData: [
      {
        id: "arizona-link",
        enName: "Arizona",
        enUrl: "/personal-banking/home-loans/construction-loans/arizona"
      },
      {
        id: "idaho-link",
        enName: "Idaho",
        enUrl: "/personal-banking/home-loans/construction-loans/idaho"
      },
      {
        id: "nevada-link",
        enName: "Nevada",
        enUrl: "/personal-banking/home-loans/construction-loans/nevada"
      },
      {
        id: "new-mexico-link",
        enName: "New Mexico",
        enUrl: "/personal-banking/home-loans/construction-loans/new-mexico"
      },
      {
        id: "oregon-link",
        enName: "Oregon",
        enUrl: "/personal-banking/home-loans/construction-loans/oregon"
      },
      {
        id: "texas-link",
        enName: "Texas",
        enUrl: "/personal-banking/home-loans/construction-loans/texas"
      },
      {
        id: "utah-link",
        enName: "Utah",
        enUrl: "/personal-banking/home-loans/construction-loans/utah"
      },
      {
        id: "washington-link",
        enName: "Washington",
        enUrl: "/personal-banking/home-loans/construction-loans/washington"
      }
    ]
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit of at least one form of a monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "Our Custom Construction Loan Advantage",
      subtitle: "WaFd Bank is unique when it comes to construction loans and home building financing.",
      customSubtitle: <ServiceStatesLinks {...serviceStateLinksData} />,
      secondaryButton: {
        title: "Watch Video",
        icon: {
          position: "right",
          lib: "far",
          name: "video",
          class: "float-right mt-1 ml-2"
        }
      },
      mainButton: {
        url: "/personal-banking/home-loans/construction-loans",
        title: "Build Your Dream Home"
      },
      videoData: {
        VimeoID: "840195560"
      }
    }
  ];

  const cardsData = {
    sectionClass: "text-left pt-0",
    rowColsClass: "row-cols-1 row-cols-md-3",
    title: "",
    titleClass: "text-center",
    hasGrowEffect: false,
    hasRiseShadowEffect: true,
    useMobileView: false,
    cards: [
      {
        id: "mb-card-1",
        url: "/blog/building-remodeling/buying-land-to-build-house",
        image: {
          imgVariable: pageData.CardImage1.childImageSharp.gatsbyImageData,
          altText: "Wood model house in empty field at sunset."
        },
        title: "7 Tips for Buying Land to Build a House",
        titleClass: "text-green-60"
      },
      {
        id: "mb-card-2",
        url: "/blog/building-remodeling/3-things-know-custom-construction-loan",
        image: {
          imgVariable: pageData.CardImage2.childImageSharp.gatsbyImageData,
          altText: "House project in progress."
        },
        title: "3 Things to Consider in a Custom Construction Loan",
        titleClass: "text-green-60"
      },
      {
        id: "mb-card-3",
        url: "/blog/building-remodeling/construction-to-permanent-loans-explained",
        image: {
          imgVariable: pageData.CardImage3.childImageSharp.gatsbyImageData,
          altText: "Construction to Permanent Loan Advantages & How They Work"
        },
        title: "Construction-to-Permanent Loans Explained",
        titleClass: "text-green-60"
      }
    ]
  };

  const AVG_PRICE = 350000;
  const calculatorData = {
    title: "Land Loan",
    loanType: "Lot Loan",
    loanTerm: "20 Year Fixed",
    showOtherCalcLinks: true,
    interestRate: 0,
    estimatedPayment: null,
    purchasePrice: AVG_PRICE,
    downPaymentPercent: 30,
    downPaymentAmount: AVG_PRICE * 0.3,
    loanAmount: AVG_PRICE - AVG_PRICE * 0.3,
    disclaimersSectionClass: "py-0"
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you're a first time home buyer and have done all your research but want help, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have."
  };

  const faqAccordionData = {
    id: "land-loans-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "land-loans-see-more-faqs-link",
    faqCategoryNames: ["Land Loans"]
  };

  const landLoanChecklistData = {
    greenCircleCheckmark: true,
    accountName: "land-loans-checklist",
    items: [
      {
        id: 1,
        text: "70% LTV, Improved Land"
      },
      {
        id: 2,
        text: "Available on loan amounts up to $700,000"
      },
      {
        id: 3,
        text: "20 year term available"
      }
    ]
  };

  const [showLOModal, setShowLOModal] = useState(false);
  const handleCloseLOModal = () => setShowLOModal(false);
  const handleShowLOModal = () => {
    setShowLOModal(true);
  };

  const preApprovalData = {
    title: "Lot and Land with a Pre-Approval*"
  };

  return (
    <SecondaryLanding showMortgageNotification={true} loanType="Lot Loans">
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container pb-0">
        <h1>Lot and Land Loans</h1>
        <div className="row">
          <div className="col-md-6">
            <h3>
              With a Lot Loan from WaFd Bank, get the financing you need to buy the perfect spot for your new home.
            </h3>
            <h4>
              Consider a WaFd lot loan and receive a discount on closing costs when you finance{" "}
              <Link to="/personal-banking/home-loans/construction-loans/" id="construction-page-link">
                construction
              </Link>{" "}
              of your custom home with us within two years. We offer a 20-year term lot loan for long-term plans. Apply
              online, where you can upload your documents and receive status updates in our secure portal.
            </h4>
          </div>
          <div className="col-md-6">
            <h5>Land Loan Checklist</h5>
            <List {...landLoanChecklistData} />
            <div className="row">
              <LoanOfficerSearch
                isModalSearch={true}
                show={showLOModal}
                handleClose={handleCloseLOModal}
                loanType="Land Loan"
              />
              <div className="col-sm-6 col-md-12 col-lg-6 mb-3">
                <Button
                  class="btn-primary w-100"
                  type="button"
                  text="Apply Now"
                  id="mortgage-application-cta"
                  showIcon={false}
                  onClick={handleShowLOModal}
                />
              </div>
              <div className="col-sm-6 col-md-12 col-lg-6 mb-3">
                <Button
                  class="btn-link w-100"
                  url="/personal-banking/home-loans/mortgage-rates"
                  text="See Today's Rates"
                  id="rates-btn"
                  showIcon={false}
                />
              </div>
            </div>
          </div>
        </div>
        <ReturningBorrowerLink loanType="Lot Loan" />
      </section>

      <MortgageCalculator {...calculatorData} />
      <PreApprovalWorkflow {...preApprovalData} />

      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />

      <NewUIWafdBankBenefits />

      <FaqAccordion {...faqAccordionData} />

      <StretchedLinkCards {...cardsData} />

      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default LotLoans;
